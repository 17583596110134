@media (min-width: 1px) {
  .devvn_toolbar {
    position: fixed;
    bottom: 45%;
    left: 20px;
    z-index: 99999999;
  }
  .devvn_toolbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .devvn_toolbar ul li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .devvn_toolbar ul li a {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    margin: 0 0 10px;
    position: relative;
    background-position: 50% 50%;
    background-size: cover;
    overflow: inherit;
  }
  .devvn_toolbar ul li a br {
    display: none;
  }
  .devvn_toolbar ul li a span {
    font-weight: 400;
    color: #333;
    position: absolute;
    top: 50%;
    left: calc(100% + 10px);
    left: -webkit-calc(100% + 10px);
    left: -moz-calc(100% + 10px);
    margin-top: -12.5px;
    font-size: 14px;
    height: 25px;
    line-height: 25px;
    padding: 0 10px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
  }
  .devvn_toolbar ul li a:hover span {
    opacity: 1;
    visibility: visible;
  }
  .devvn_toolbar ul li a span:after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #333;
    border-width: 5px;
    margin-top: -5px;
  }
  .devvn_toolbar ul li a img {
    width: auto;
    height: auto;
    max-width: 29px;
    max-height: 29px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    -moz-transform: translate3d(-50%, -50%, 0);
    -webkit-transform: translate3d(-50%, -50%, 0);
    margin: 0;
  }
}
@media (max-width: 0px) {
  body.has_devvn_toolbar {
    padding-bottom: 50px;
  }

  .devvn_toolbar {
    background: #fff;
    display: inline-block;
    width: 100%;

    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 99999999;
    height: auto;
    padding: 0;
    border-top: 0;

    visibility: hidden;
    opacity: 0;

    transform: translate3d(0, 120%, 0);
    -moz-transform: translate3d(0, 120%, 0);
    -webkit-transform: translate3d(0, 120%, 0);

    transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
  }

  .show_contactfix .devvn_toolbar {
    transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    visibility: visible;
    opacity: 1;
  }

  .devvn_toolbar {
    border-top: 1px solid #cbcbcb;
  }

  .devvn_toolbar ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: table;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  .devvn_toolbar ul li {
    text-align: center;
    display: table-cell;
    vertical-align: top;
  }

  .devvn_toolbar ul li a {
    display: block;
    width: 100%;
    outline: none;
    text-decoration: none;
    padding: 5px 3px;
  }

  .devvn_toolbar ul li a span {
    font-weight: 400;
    color: #333;
    font-size: 3.5vw;
    display: block;
  }

  .devvn_toolbar ul li a img {
    height: 6vw;
    width: auto;
    margin: 0;
  }

  .woocommerce-checkout .devvn_toolbar {
    display: none !important;
  }
  .devvn_toolbar a.devvn_buy_now.devvn_buy_now_style {
    margin: 0;
    border-radius: 0;
    padding: 5px 3px;
    font-size: initial;
    line-height: inherit;
  }

  .devvn_toolbar a.devvn_buy_now.devvn_buy_now_style span {
    margin: 0;
    padding: 0;
  }
  .devvn_toolbar a.devvn_buy_now.devvn_buy_now_style span {
    background: none !important;
  }
}
.mfp-wrap,
.mfp-bg {
  z-index: 999999999;
}

@media (max-width: 1425px) {
  .devvn_toolbar {
    display: none;
  }
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blinking-time-live {
  animation: blink 0.5s infinite;
}
